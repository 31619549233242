var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: `推广：${_vm.title || "-"}`,
        visible: _vm.visible,
        width: "500px",
        "before-close": _vm.beforeClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "scan-container" },
        [
          _vm._v("\n    利电充微信小程序\n    "),
          _c(
            "el-image",
            {
              staticStyle: { width: "150px", height: "150px", margin: "30px" },
              attrs: { src: _vm.url, fit: "fill" },
            },
            [
              _c("div", { attrs: { slot: "error" }, slot: "error" }, [
                _c("div", { staticClass: "image-slot" }, [
                  _c("i", { staticClass: "el-icon-loading" }),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleDownload(_vm.url)
                },
              },
            },
            [_vm._v("下载小程序码")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [_c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")])],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }