var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: `优惠券记录：【${_vm.currentItem.mobile || "-"}】`,
        visible: _vm.visible,
        width: "60%",
        "before-close": _vm.beforeClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, "header-align": "center", border: "" },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              align: "center",
              label: "序号",
              "min-width": "50",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              align: "center",
              label: "优惠券名称",
              "min-width": "180",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "state",
              align: "center",
              label: "优惠券状态",
              "min-width": "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("stateFilter")(row.state)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "receiveTime",
              align: "center",
              label: "领取时间",
              "min-width": "180",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "useTime",
              align: "center",
              label: "使用时间",
              "min-width": "180",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "expireTime",
              align: "center",
              label: "过期时间",
              "min-width": "180",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "orderNumber",
              align: "center",
              label: "充电订单号",
              "min-width": "260",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.openOrder(row.orderNumber)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(row.orderNumber) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        staticStyle: { "align-self": "flex-end", "margin-top": "10px" },
        attrs: {
          background: false,
          total: _vm.pageParams.total,
          page: _vm.pageParams.pageNum,
          limit: _vm.pageParams.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.pageParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.pageParams, "pageSize", $event)
          },
          pagination: _vm.getDataList,
        },
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }