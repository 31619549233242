<template>
  <el-dialog
    :title="`手动停止：${username}`"
    :visible.sync="visible"
    width="500px"
    :before-close="beforeClose"
  >
    <p style="color: rgba(0,0,0,0.65)">确定停止该会员活动？</p>
    <br />
    <div style="font-weight: 600;">
      <p>
        <span style="font-weight: 500;color: rgba(0,0,0,0.65)">停止后 </span
        >活动不可恢复，<span style="font-weight: 500;color: rgba(0,0,0,0.65)"
          >{{ username }} </span
        >将不再享受充电会员权益，已领取的优惠券不受影响。
      </p>
      <p>此操作不可逆，请谨慎操作</p>
    </div>
    <el-form
      label-position="top"
      ref="form"
      :rules="{
        desc: [{ required: true, message: '请填写停止原因', trigger: 'blur' }],
      }"
      :validate-on-rule-change="false"
      :model="form"
      label-width="80px"
    >
      <el-form-item label="停止原因" prop="desc">
        <el-input
          type="textarea"
          v-model="form.desc"
          :rows="3"
          show-word-limit
          maxlength="150"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="debouncedHandleClick">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { debounce } from "lodash-es";
export default {
  props: {
    username: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: false,
      form: {
        desc: "",
      },
      debouncedHandleClick: debounce(this.submit, 1000, {
        leading: true,
        trailing: false,
      }),
    };
  },
  mounted() {},
  beforeDestroy() {
    if (this.debouncedHandleClick) {
      this.debouncedHandleClick.cancel();
    }
  },
  methods: {
    open() {
      this.visible = true;
    },
    close() {
      this.beforeClose(() => {
        this.visible = false;
      });
    },
    beforeClose(done) {
      this.$refs.form.resetFields();
      done();
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        this.$emit("submit", this.form.desc);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  line-height: 30px;
}
</style>
