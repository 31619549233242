var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "500px",
        "before-close": _vm.beforeClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _vm.title === "使失效"
        ? _c("div", { staticStyle: { "font-weight": "600" } }, [
            _c("p", [
              _vm._v(
                "确定使【" + _vm._s(_vm.mobile || "-") + "】会员权益失效？"
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      操作成功后该会员的会员权益将立刻失效，已领取的优惠券不受影响\n    "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.title === "恢复会员权益"
        ? _c("div", { staticStyle: { "font-weight": "600" } }, [
            _c("p", [
              _vm._v(
                "确定使【" + _vm._s(_vm.mobile || "-") + "】会员权益恢复原状？"
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "操作成功后该会员将恢复原状态（若已过期则无法享受会员权益）"
              ),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v("注：已经错过的优惠券无法补回")]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "top",
            rules: {
              desc: [
                { required: true, message: "请填写备注", trigger: "blur" },
              ],
            },
            "validate-on-rule-change": false,
            model: _vm.form,
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "备注", required: "", prop: "desc" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 3,
                  "show-word-limit": "",
                  maxlength: "150",
                },
                model: {
                  value: _vm.form.desc,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "desc", $$v)
                  },
                  expression: "form.desc",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }