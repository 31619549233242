<template>
  <el-dialog
    class="coupon-add-dialog"
    title="新增优惠券"
    :visible.sync="visible"
    width="600px"
    @close="handleClose"
  >
    <div style="padding-right: 24px">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="140px"
        label-suffix="："
        :validate-on-rule-change="false"
      >
        <el-form-item label="使用门槛" required>
          <div class="flex-x-t">
            <el-select
              :disabled="form.deleteable === false"
              v-model="form.type"
              style="width: 100%"
              placeholder=""
            >
              <el-option
                v-for="(item, key) in couponRangeDic"
                :key="key"
                :value="+key"
                :label="item"
              ></el-option>
            </el-select>
            <span class="connector">满</span>
            <div>
              <el-form-item class="nomargin-form-item" label="" prop="reach">
                <el-input
                  style="width: 200px"
                  :disabled="form.deleteable === false"
                  onkeyup="this.value=this.value.replace(/[^\d]/g,'');"
                  :maxlength="5"
                  placeholder="0-50，无门槛请填0"
                  v-model="form.reach"
                >
                  <template slot="append"
                    >元</template
                  >
                </el-input>
                <!-- <div class="suffix-text">无门槛请填0</div> -->
              </el-form-item>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="优惠方式" required>
          <div class="flex-x-t">
            <div style="width: 100%">
              {{ couponRangeDic[form.type] }}
            </div>
            <span class="connector">{{
              couponTypeActionsDic[form.couponType]
            }}</span>

            <el-form-item
              class="nomargin-form-item"
              label=""
              prop="sub"
              style="margin-bottom: 10px !important"
            >
              <el-input
                style="width: 200px"
                :disabled="form.deleteable === false"
                :maxlength="5"
                :placeholder="'0.1-50'"
                v-model="form.sub"
              >
                <template slot="append">{{
                  couponTypeUnitDic[form.couponType]
                }}</template>
              </el-input>
              <div class="suffix-text">先减免服务费，不足再减免电费</div>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item label="优惠券名称" prop="name" class="only-read-input">
          <div v-if="form.deleteable === false">{{ form.name }}</div>
          <el-input
            v-else
            :disabled="form.deleteable === false"
            v-model="form.name"
            disabled
            placeholder="移动端可见"
            maxlength="30"
          ></el-input>
        </el-form-item>
        <el-form-item label="每人每次奖励" prop="num">
          <div v-if="form.deleteable === false">{{ form.num }}</div>
          <el-input v-else placeholder="" class="input-with-select">
            <el-select
              v-model="form.num"
              slot="prepend"
              placeholder="请选择"
              :disabled="form.deleteable === false"
            >
              <el-option
                v-for="count in 10"
                :key="count"
                :label="count"
                :value="count"
              ></el-option>
            </el-select>
            <template slot="append"
              >张</template
            >
          </el-input>
        </el-form-item>
        <el-form-item label="发放上限（总）" prop="limitType">
          <div>
            <el-radio
              :label="0"
              v-model="form.limitType"
              :disabled="form.deleteable === false"
              >限制
              <el-form-item class="nomargin-form-item" label="" prop="total">
                <el-input
                  :disabled="
                    ![false, undefined].includes(form.deleteable) ||
                      form.limitType === 1
                  "
                  style="margin-right: -20px"
                  :maxlength="30"
                  placeholder="请输入≥1的正整数"
                  v-model="form.total"
                >
                  <template slot="append"
                    >张</template
                  >
                </el-input>
              </el-form-item>
            </el-radio>
            <el-radio
              :label="1"
              v-model="form.limitType"
              :disabled="form.deleteable === false"
              >不限</el-radio
            >
          </div>
          <div
            v-if="form.id && form.limitType !== 1"
            class="suffix-text"
            :style="{
              color:
                form.ratio >= 100 ? '#F56C6C' : form.ratio >= 90 ? '#f90' : '',
            }"
          >
            {{
              form.remainingCount === -1
                ? `发放详情：${form.issuedNum}张`
                : `发放详情：发放${form.ratio}%（已领：${
                    form.issuedNum
                  }/剩余：${form.remainingCount}）`
            }}
          </div>
        </el-form-item>

        <el-form-item label="券有效期" prop="expireType">
          <el-radio-group
            v-model="form.expireType"
            :disabled="form.deleteable === false"
          >
            <el-radio :label="0">固定天数</el-radio>
            <!-- <el-radio :label="1">固定日期</el-radio> -->
            <el-radio :label="-1">长期有效</el-radio>
          </el-radio-group>
          <div v-if="form.expireType == 0">
            <el-form-item class="nomargin-form-item" label="" prop="expireDays">
              <el-input
                :maxlength="30"
                placeholder="请输入"
                :disabled="form.deleteable === false"
                v-model="form.expireDays"
              >
                <template slot="prepend"
                  >领取后</template
                >
                <template slot="append"
                  >天</template
                >
              </el-input>
            </el-form-item>
          </div>
          <div v-if="form.expireType === 1">
            <el-col :span="11">
              <el-form-item
                class="nomargin-form-item"
                label=""
                prop="startTime"
              >
                <el-date-picker
                  type="date"
                  placeholder="开始时间"
                  v-model="form.startTime"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col style="text-align: center" :span="2">至</el-col>
            <el-col :span="11">
              <el-form-item class="nomargin-form-item" label="" prop="endTime">
                <el-date-picker
                  placeholder="结束时间"
                  value-format="yyyy-MM-dd"
                  v-model="form.endTime"
                  style="width: 100%"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="loading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dayjs from "dayjs";
import { cloneDeep } from "lodash-es";

var checkThreshold = (rule, value, callback) => {
  if (value === "") {
    return callback(new Error("请输入"));
  }
  let reg = /^(?!0\d)\d+(\.\d+)?$/;
  if (!reg.test(value)) {
    return callback(new Error("请输入合法数字"));
  }
  if (+value >= 0 && +value <= 50) {
    callback();
  } else {
    callback(new Error("门槛金额限制范围为0-50元"));
  }
};
var checkLimitNums = (rule, value, callback) => {
  if (!value) {
    return callback(new Error("请输入"));
  }
  if (+value >= 0 && +value <= 100000) {
    callback();
  } else {
    callback(new Error("发放上限限制范围为100000内正整数"));
  }
};
var checkExpireDays = (rule, value, callback) => {
  if (!value) {
    return callback(new Error("请输入"));
  }
  if (+value >= 3 && +value <= 365) {
    callback();
  } else {
    callback(new Error("固定天数限制范围为3-365"));
  }
};

export default {
  data() {
    return {
      couponRangeDic: Object.freeze({
        1: "总金额",
        2: "服务费",
      }),
      couponTypeUnitDic: Object.freeze({
        0: "元",
        1: "折",
      }),
      couponTypeActionsDic: Object.freeze({
        0: "减",
        1: "打",
      }),
      form: {
        couponType: 0,
        type: 2, // 优惠范围  1 总金额 2 服务费
        reach: "",
        sub: "",
        name: "",
        num: 1,
        issuedNum: 0, // 已发放数量
        total: "",
        limitType: 1, // 发放上限类型 0数量有限 1数量不限
        expireType: 0, // 过期类型 0固定天数 1固定日期 2长期
        expireDays: "30",
        startTime: "",
        endTime: "",
        totalHistory: 0,
      },
      loading: false,
      visible: false,
      mode: "add",
    };
  },
  watch: {
    splicingNames(val) {
      this.form.name = val;
    },
  },
  computed: {
    splicingNames() {
      if (this.form.reach === "0") {
        return `${this.couponRangeDic[this.form.type]}无门槛${
          this.couponTypeActionsDic[this.form.couponType]
        }${this.form.sub}${this.couponTypeUnitDic[this.form.couponType]}`;
      }
      if (this.form.sub && this.form.reach) {
        return `${this.couponRangeDic[this.form.type]}满${this.form.reach}元${
          this.couponTypeActionsDic[this.form.couponType]
        }${this.form.sub}${this.couponTypeUnitDic[this.form.couponType]}`;
      }
      return "";
    },
    pickerOptions() {
      return {
        disabledDate(time) {
          let after =
            dayjs()
              .add(1, "year")
              .unix() * 1000; //后一周毫秒数
          return (
            time.getTime() > after ||
            time.getTime() <
              dayjs()
                .subtract(1, "day")
                .endOf("day")
                .unix() *
                1000
          );
        },
      };
    },
    rules() {
      return {
        couponType: [
          {
            required: true,
            message: "请选择优惠券类型",
            trigger: "blur",
          },
        ],
        reach: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
          {
            validator: checkThreshold,
            trigger: "blur",
          },
        ],
        sub: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
          {
            validator: this.checkSub,
            trigger: "blur",
          },
        ],
        maxDiscount: [
          {
            required: this.form.maxDiscountType === 1,
            message: "请输入",
            trigger: "blur",
          },
          {
            validator:
              this.form.maxDiscountType === 1
                ? this.checkMaxDiscount
                : undefined,
            trigger: "blur",
          },
        ],
        // name: [
        //   {
        //     required: true,
        //     message: "请选择优惠券名称",
        //     trigger: "change",
        //   },
        // ],
        num: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        limitType: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        total: [
          {
            required: this.form.limitType === 0,
            message: "请输入",
            trigger: "change",
          },
          {
            required: this.form.limitType === 0,
            validator: this.form.limitType === 0 ? checkLimitNums : undefined,
            trigger: "change",
          },
        ],
        expireType: [
          {
            required: true,
            message: "请选择",
            trigger: "change",
          },
        ],
        expireDays: [
          {
            required: this.form.expireType === 0,
            message: "请输入",
            trigger: "change",
          },
          {
            validator: this.form.expireType === 0 ? checkExpireDays : undefined,
            trigger: "change",
          },
        ],
        startTime: [
          {
            required: this.form.expireType === 1,
            message: "请选择",
            trigger: "change",
          },
          {
            validator:
              this.form.expireType === 1 ? this.checkDateDrop : undefined,
            trigger: "change",
          },
        ],
        endTime: [
          {
            required: this.form.expireType === 1,
            message: "请选择",
            trigger: "change",
          },
          {
            validator:
              this.form.expireType === 1 ? this.checkDateDrop : undefined,
            trigger: "change",
          },
        ],
      };
    },
  },
  methods: {
    open(type = "add", row) {
      this.mode = type;
      if (type === "edit") {
        this.form = Object.assign({}, this.form, row);
        let { couponType, sub, total } = row;
        // 单独处理字段
        switch (couponType) {
          case 0:
            this.form.sub = sub;
            break;
        }
      }

      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    onNumberKeyup(e) {
      console.log(e);
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.totalHistory > this.form.total) {
            this.$message.error(
              "优惠券总数不能小于历史总数：" + this.form.totalHistory
            );
            return;
          }
          this.$emit("submit", cloneDeep({ ...this.form, mode: this.mode }));
          this.close();
        }
      });
    },
    checkMaxDiscount(rule, value, callback) {
      if (!value) {
        return callback(new Error("请输入"));
      }
      let reg = /^[1-9]\d*$/;
      if (!reg.test(value)) {
        return callback(new Error("请输入正整数"));
      }
      let max = 50;
      if (+value > 0 && +value <= +max) {
        callback();
      } else {
        callback(new Error("减免金额需要小于使用门槛"));
      }
    },
    checkSub(rule, value, callback) {
      if (!value) {
        return callback(new Error("请输入"));
      }
      let reg = /^\d+$|^\d+\.\d$/g;
      if (!reg.test(value)) {
        return callback(new Error("请输入0.1-50的数值，保留一位小数"));
      }
      let max = 50;
      if (+value > 0 && +value <= +max) {
        callback();
      } else {
        callback(new Error("减免金额需要小于使用门槛"));
      }
    },

    checkDateDrop(rule, value, callback) {
      if (!value) {
        return callback(new Error("请输入"));
      }
      if (this.form.endTime && this.form.startTime) {
        if (dayjs(this.form.endTime).isBefore(dayjs(this.form.startTime))) {
          callback(new Error("开始时间不能大于结束时间"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.form = {
        couponType: 0,
        type: 2, // 优惠范围  1 总金额 2 服务费
        reach: "",
        sub: "",
        name: "",
        num: 1,
        issuedNum: 0,
        total: "",
        limitType: 1, // 发放上限类型 0数量有限 1数量不限
        expireType: 0, // 过期类型 0固定天数 1固定日期 -1长期
        expireDays: "30",
        startTime: "",
        endTime: "",
      };
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-x {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.flex-x-t {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.flex-y {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.flex-x-y {
  display: flex;
  flex-direction: row;
}
.coupon-add-dialog {
  ::v-deep .el-form-item {
    margin-bottom: 24px !important;
  }
  ::v-deep .el-input-group__append {
    padding: 0 8px;
  }
  ::v-deep .el-input-group__prepend {
    padding: 0 8px;
    background-color: #fff;
  }
  ::v-deep .el-input-group__prepend .el-select {
    margin: -10px;
  }
  .nomargin-form-item {
    margin-bottom: 0 !important;
    display: inline-block;
  }
}
.connector {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFangSC-Regular;
  font-weight: normal;
  color: #4e5969;
  padding: 0 8px;
}
.suffix-text {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFangSC-Regular;
  font-weight: normal;
  color: #c9cdd4;
  position: absolute;
  margin-top: 5px;
}

.input-with-select {
  ::v-deep .el-input-group__prepend + input.el-input__inner {
    padding: 0;
    border-left: none;
  }
  ::v-deep .el-input-group__prepend {
    background-color: #fff;
    width: 100%;
    .el-select {
      width: calc(100% + 20px);
    }
  }
  ::v-deep .el-input__suffix {
    right: 0;
  }
}
.available-time-periods {
  ::v-deep .el-input--prefix .el-input__inner {
    padding-left: 16px;
  }
}

.el-form-item.is-error {
  ::v-deep.el-input-group__prepend {
    border-color: #f56c6c;
  }
}
.time-scope-setting {
  font-size: 18px;
  display: flex;
  height: 30px;
  align-items: center;
  i {
    padding-left: 10px;
    cursor: pointer;
    &.el-icon-circle-plus-outline {
      color: #3180ff;
    }
  }
}
.only-read-input {
  ::v-deep .is-disabled .el-input__inner {
    background-color: transparent !important;
    border-color: #c0c4cc !important;
    color: #606266;
  }
}
</style>
