<template>
  <el-dialog
    :title="`优惠券记录：【${currentItem.mobile || '-'}】`"
    :visible.sync="visible"
    width="60%"
    :before-close="beforeClose"
  >
    <el-table
      :data="tableData"
      header-align="center"
      border
      v-loading="loading"
      style="width: 100%"
    >
      <el-table-column type="index" align="center" label="序号" min-width="50">
      </el-table-column>
      <el-table-column
        prop="name"
        align="center"
        label="优惠券名称"
        min-width="180"
      >
      </el-table-column>
      <el-table-column
        prop="state"
        align="center"
        label="优惠券状态"
        min-width="120"
      >
        <template slot-scope="{ row }">
          {{ row.state | stateFilter }}
        </template>
      </el-table-column>
      <el-table-column
        prop="receiveTime"
        align="center"
        label="领取时间"
        min-width="180"
      >
      </el-table-column>
      <el-table-column
        prop="useTime"
        align="center"
        label="使用时间"
        min-width="180"
      >
      </el-table-column>
      <el-table-column
        prop="expireTime"
        align="center"
        label="过期时间"
        min-width="180"
      >
      </el-table-column>
      <el-table-column
        prop="orderNumber"
        align="center"
        label="充电订单号"
        min-width="260"
      >
        <template slot-scope="{ row }">
          <el-button type="text" @click="openOrder(row.orderNumber)">
            {{ row.orderNumber }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      style="align-self: flex-end; margin-top: 10px"
      :background="false"
      :total="pageParams.total"
      :page.sync="pageParams.pageNum"
      :limit.sync="pageParams.pageSize"
      @pagination="getDataList"
    />
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Pagination from "@/components/Pagination/index";
import { getMemberUserCouponList } from "@/api/member";
import { COUPON_STATUS, findLabelByValue } from "@/utils/const";
export default {
  components: { Pagination },
  props: {
    title: {
      type: String,
      default: "使失效",
    },
  },
  data() {
    return {
      visible: false,
      pageParams: {
        total: 0,
        pageNum: 1,
        pageSize: 20,
      },
      tableData: [],
      currentItem: {},
      loading: false,
    };
  },
  filters: {
    stateFilter(state) {
      return findLabelByValue(COUPON_STATUS, state);
    },
  },
  methods: {
    getDataList({ page, limit }) {
      this.loading = true;
      this.pageParams.pageNum = page;
      let param = {
        mobile: this.currentItem.mobile,
        pageNo: this.pageParams.pageNum,
        pageSize: this.pageParams.pageSize,
        memberId: this.currentItem.memberId,
      };
      getMemberUserCouponList(param)
        .then((res) => {
          this.loading = false;
          if (res.message.code === 0) {
            this.tableData = res?.data?.records || [];
            this.pageParams.total = res.data.total || 0;
          } else {
            this.$message.error(res.message.message);
          }
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    open(row) {
      this.currentItem = row;
      this.pageParams.pageNum = 1;
      this.getDataList(row);
      this.visible = true;
    },
    close() {
      this.beforeClose(() => {
        this.visible = false;
      });
    },
    beforeClose(done) {
      done();
    },
    submit() {
      this.$emit("submit", this.form);
    },
    openOrder(id) {
      this.$router.push({
        path: `/order/orderDetail/${id}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  line-height: 30px;
}
</style>
