<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="500px"
    :before-close="beforeClose"
  >
    <div style="font-weight: 600;" v-if="title === '使失效'">
      <p>确定使【{{ mobile || "-" }}】会员权益失效？</p>
      <p>
        操作成功后该会员的会员权益将立刻失效，已领取的优惠券不受影响
      </p>
    </div>
    <div style="font-weight: 600;" v-if="title === '恢复会员权益'">
      <p>确定使【{{ mobile || "-" }}】会员权益恢复原状？</p>
      <p>操作成功后该会员将恢复原状态（若已过期则无法享受会员权益）</p>
      <p>注：已经错过的优惠券无法补回</p>
    </div>
    <el-form
      label-position="top"
      ref="form"
      :rules="{
        desc: [{ required: true, message: '请填写备注', trigger: 'blur' }],
      }"
      :validate-on-rule-change="false"
      :model="form"
      label-width="80px"
    >
      <el-form-item label="备注" required prop="desc">
        <el-input
          type="textarea"
          v-model="form.desc"
          :rows="3"
          show-word-limit
          maxlength="150"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "使失效",
    },
    mobile: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: false,
      form: {
        desc: "",
      },
    };
  },
  methods: {
    open() {
      this.visible = true;
    },
    close() {
      this.beforeClose(() => {
        this.visible = false;
      });
    },
    beforeClose(done) {
      this.$refs.form.resetFields();
      done();
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit("submit", this.form);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  line-height: 30px;
}
</style>
