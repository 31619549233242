<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="1000px"
    @close="handleClose"
  >
    <div>
      <el-form
        :model="searchForm"
        ref="form"
        :rules="rules"
        label-width="100px"
        label-suffix="："
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="站点名称" prop="plotName">
              <el-input
                style="width: 100%"
                v-model="searchForm.plotName"
                placeholder="请输入内容"
                maxlength="50"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所在区域" prop="codes">
              <AreaCascader
                style="width: 100%"
                placeholder="请选择（可多选）"
                v-model="searchForm.codes"
                :cascader-props="{
                  checkStrictly: true,
                  multiple: true,
                  emitPath: false,
                }"
                ref="cascaderArea"
              ></AreaCascader>
            </el-form-item>
          </el-col>
        </el-row>

        <div style="text-align: end; margin-bottom: 16px; margin-top: -16px">
          <el-button type="primary" icon="el-icon-search" @click="getPage"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" @click="handleClear"
            >重置</el-button
          >
        </div>
      </el-form>

      <el-table
        ref="multipleTable"
        row-key="id"
        border
        :data="tableData"
        v-loading="loading"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          v-for="col in columns"
          :prop="col.id"
          :key="col.id"
          v-bind="col"
        >
        </el-table-column>
      </el-table>
      <pagination
        style="text-align: end; margin-top: 10px"
        :background="false"
        :total="pageParams.total"
        :page.sync="pageParams.pageNum"
        :limit.sync="pageParams.pageSize"
        @pagination="getPage"
      />
    </div>
    <span slot="footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
// import { getEquipStationPage } from "@/api/assetManage/stationManage/stationManageApi";
// import { queryStandardCodeApi } from "@/api/systemManage/businessDic/dic";
import { getMemberPlots } from "@/api/member";
import AreaCascader from "@/components/AreaCascader/index";
import Pagination from "@/components/Pagination/index";
import { isEmpty } from "lodash-es";
export default {
  components: { AreaCascader, Pagination },
  props: {
    title: {
      type: String,
      default: "选择指定站点",
    },
  },
  data() {
    return {
      searchForm: {
        codes: [],
        plotName: "",
      },
      visible: false,
      tableData: [],
      loading: false,
      echoList: [],
      stationTypeDic: [],
      pageParams: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
      },
      options: [],
      multipleSelection: [],
      rules: {},
      columns: [
        {
          type: "selection",
          width: 55,
          headerAlign: "center",
          align: "center",
          "reserve-selection": true,
        },
        {
          id: "prop",
          label: "序号",
          minWidth: 50,
          type: "index",
          headerAlign: "center",
          align: "center",
        },
        {
          id: "plotName",
          label: "站点名称",
          minWidth: 160,
          "show-overflow-tooltip": true,
          headerAlign: "center",
          align: "center",
        },
        {
          id: "stationAreaName",
          label: "所在地区",
          minWidth: 180,
          "show-overflow-tooltip": true,
          headerAlign: "center",
          align: "center",
          formatter: (row) => {
            return `${row.province || ""} ${row.city || ""} ${row.area || ""}`;
          },
        },
      ],
    };
  },
  created() {},
  methods: {
    open(row) {
      this.visible = true;
      this.echoList = row || [];
      this.getPage();
    },
    close() {
      this.visible = false;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getMemberPlots() {},
    getPage({ page } = {}) {
      this.pageParams.pageNum = page || 1;
      let params = {
        pageNo: this.pageParams.pageNum,
        pageSize: this.pageParams.pageSize,
        ...this.searchForm,
      };
      this.loading = true;
      getMemberPlots(params)
        .then((data) => {
          this.loading = false;
          let list = data.data.records || [];
          list.forEach((item) => {
            let selected = this.echoList.find((echo) => echo.id === item.id);

            this.$nextTick(() => {
              if (selected)
                this.$refs.multipleTable.toggleRowSelection(item, true);
            });
          });
          this.tableData = list;

          this.pageParams.total = data.data.total;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    handleClear() {
      this.$refs.form.resetFields();
    },
    handleClose() {
      this.pageParams.pageNum = 1;
      this.pageParams.pageSize = 10;
      this.pageParams.total = 0;
      this.handleClear();
      this.$refs.multipleTable.clearSelection();
    },

    submit() {
      this.$emit("submit", this.multipleSelection);
    },
    // selectable(row, index) {
    //   return this.title === "选择指定站点" ? !row.isOther : true;
    // },
  },
};
</script>

<style lang="scss"></style>
