var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: `手动停止：${_vm.username}`,
        visible: _vm.visible,
        width: "500px",
        "before-close": _vm.beforeClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("p", { staticStyle: { color: "rgba(0,0,0,0.65)" } }, [
        _vm._v("确定停止该会员活动？"),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticStyle: { "font-weight": "600" } }, [
        _c("p", [
          _c(
            "span",
            {
              staticStyle: { "font-weight": "500", color: "rgba(0,0,0,0.65)" },
            },
            [_vm._v("停止后 ")]
          ),
          _vm._v("活动不可恢复，"),
          _c(
            "span",
            {
              staticStyle: { "font-weight": "500", color: "rgba(0,0,0,0.65)" },
            },
            [_vm._v(_vm._s(_vm.username) + " ")]
          ),
          _vm._v("将不再享受充电会员权益，已领取的优惠券不受影响。\n    "),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("此操作不可逆，请谨慎操作")]),
      ]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "top",
            rules: {
              desc: [
                { required: true, message: "请填写停止原因", trigger: "blur" },
              ],
            },
            "validate-on-rule-change": false,
            model: _vm.form,
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "停止原因", prop: "desc" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 3,
                  "show-word-limit": "",
                  maxlength: "150",
                },
                model: {
                  value: _vm.form.desc,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "desc", $$v)
                  },
                  expression: "form.desc",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.debouncedHandleClick },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }