<template>
  <section class="card">
    <div class="card-left">
      <div class="price">
        <span class="price-type">￥</span>
        <span class="price-num">{{ coupon.sub }}</span>
      </div>
      <div class="price-info">满{{ coupon.reach }}元可用</div>
    </div>
    <div class="card-right">
      <p class="card-name" v-overflow-tooltip>{{ coupon.name }}</p>
      <p class="card-time">{{ canUseRange }}</p>
      <p class="card-time" v-overflow-tooltip v-if="plotNames.length > 0">
        适用电站：{{ (plotNames || []).join("、") }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    coupon: {
      type: Object,
      default: () => {},
    },
    plotNames: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    canUseRange() {
      if (this.coupon.expireDays > 0 && this.coupon.expireType === 0) {
        return `领取后${this.coupon.expireDays}天内有效`;
      } else {
        return "长期有效";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  align-items: center;
  background: linear-gradient(131deg, #fcf6dc 0%, #ffecc3 100%);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  margin: 5px 0;
  -webkit-mask-image: radial-gradient(
      circle at 88px 4px,
      transparent 4px,
      red 4.5px
    ),
    radial-gradient(closest-side circle at 50%, red 99%, transparent 100%);
  -webkit-mask-size: 100%, 2px 4px;
  -webkit-mask-repeat: repeat, repeat-y;
  -webkit-mask-position: 0 -4px, 87px;
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
}
.card-left {
  width: 88px;
  text-align: center;
}
.card-right {
  padding: 16px 12px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex: 1;
  min-width: 0;
}
.card-info {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: rgba(33, 32, 44, 0.9);
}
.card-time {
  font-size: 12px;
  line-height: 20px;
  font-weight: normal;
  color: #f22424;
  margin-top: 4px;
}
.price-num {
  font-family: D-DIN-PRO, D-DIN-PRO;
  font-weight: 600;
  font-size: 24px;
  color: #bb731d;
  line-height: 28px;
}
.price-type {
  font-family: D-DIN-PRO, D-DIN-PRO;
  font-weight: 600;
  font-size: 14px;
  color: #bb731d;
  line-height: 22px;
}
.price-info {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 14px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
.card-name {
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 20px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
.card-time {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 14px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
</style>
