<template>
  <el-dialog
    :title="`推广：${title || '-'}`"
    :visible.sync="visible"
    width="500px"
    :before-close="beforeClose"
  >
    <div class="scan-container">
      利电充微信小程序
      <el-image
        style="width: 150px; height: 150px;margin: 30px;"
        :src="url"
        fit="fill"
      >
        <div slot="error">
          <div class="image-slot">
            <i class="el-icon-loading"></i>
          </div>
        </div>
      </el-image>
      <el-button type="primary" @click="handleDownload(url)"
        >下载小程序码</el-button
      >
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getMemberQrCode } from "@/api/member";
export default {
  props: {
    mobile: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: false,
      url: "",
      title: "会员",
    };
  },
  methods: {
    open(row) {
      this.title = row.memberName;
      getMemberQrCode({
        envVersion:
          process.env.NODE_ENV === "development" ? "trial" : "release",
        page: "pages/index/indexNew",
        scene: "/wode/comemberdetail?id=" + row.memberId,
      }).then((res) => {
        this.url = res.data;
      });
      this.visible = true;
    },
    close() {
      this.beforeClose(() => {
        this.visible = false;
      });
    },
    beforeClose(done) {
      this.url = "";
      done();
    },
    handleDownload(base64String) {
      const byteCharacters = atob(base64String.split(",")[1]);

      // 创建字节数组
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([byteArray], { type: "application/octet-stream" });

      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = (this.title || "二维码") + ".png"; // 设置下载文件名

      link.style.display = "none";
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
    submit() {
      this.$emit("submit", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
.scan-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.image-slot {
  height: 150px;
  width: 150px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
