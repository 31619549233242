var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "card" }, [
    _c("div", { staticClass: "card-left" }, [
      _c("div", { staticClass: "price" }, [
        _c("span", { staticClass: "price-type" }, [_vm._v("￥")]),
        _vm._v(" "),
        _c("span", { staticClass: "price-num" }, [
          _vm._v(_vm._s(_vm.coupon.sub)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "price-info" }, [
        _vm._v("满" + _vm._s(_vm.coupon.reach) + "元可用"),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-right" }, [
      _c(
        "p",
        {
          directives: [
            { name: "overflow-tooltip", rawName: "v-overflow-tooltip" },
          ],
          staticClass: "card-name",
        },
        [_vm._v(_vm._s(_vm.coupon.name))]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "card-time" }, [_vm._v(_vm._s(_vm.canUseRange))]),
      _vm._v(" "),
      _vm.plotNames.length > 0
        ? _c(
            "p",
            {
              directives: [
                { name: "overflow-tooltip", rawName: "v-overflow-tooltip" },
              ],
              staticClass: "card-time",
            },
            [
              _vm._v(
                "\n      适用电站：" +
                  _vm._s((_vm.plotNames || []).join("、")) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }