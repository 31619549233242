var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "coupon-add-dialog",
      attrs: { title: "新增优惠券", visible: _vm.visible, width: "600px" },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "div",
        { staticStyle: { "padding-right": "24px" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "140px",
                "label-suffix": "：",
                "validate-on-rule-change": false,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "使用门槛", required: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex-x-t" },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            disabled: _vm.form.deleteable === false,
                            placeholder: "",
                          },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        _vm._l(_vm.couponRangeDic, function (item, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: { value: +key, label: item },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "connector" }, [_vm._v("满")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "nomargin-form-item",
                              attrs: { label: "", prop: "reach" },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    disabled: _vm.form.deleteable === false,
                                    onkeyup:
                                      "this.value=this.value.replace(/[^\\d]/g,'');",
                                    maxlength: 5,
                                    placeholder: "0-50，无门槛请填0",
                                  },
                                  model: {
                                    value: _vm.form.reach,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "reach", $$v)
                                    },
                                    expression: "form.reach",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("元"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠方式", required: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex-x-t" },
                    [
                      _c("div", { staticStyle: { width: "100%" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.couponRangeDic[_vm.form.type]) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "connector" }, [
                        _vm._v(
                          _vm._s(_vm.couponTypeActionsDic[_vm.form.couponType])
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "nomargin-form-item",
                          staticStyle: { "margin-bottom": "10px !important" },
                          attrs: { label: "", prop: "sub" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                disabled: _vm.form.deleteable === false,
                                maxlength: 5,
                                placeholder: "0.1-50",
                              },
                              model: {
                                value: _vm.form.sub,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "sub", $$v)
                                },
                                expression: "form.sub",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.couponTypeUnitDic[_vm.form.couponType]
                                  )
                                ),
                              ]),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "suffix-text" }, [
                            _vm._v("先减免服务费，不足再减免电费"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "only-read-input",
                  attrs: { label: "优惠券名称", prop: "name" },
                },
                [
                  _vm.form.deleteable === false
                    ? _c("div", [_vm._v(_vm._s(_vm.form.name))])
                    : _c("el-input", {
                        attrs: {
                          disabled: _vm.form.deleteable === false,
                          disabled: "",
                          placeholder: "移动端可见",
                          maxlength: "30",
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "每人每次奖励", prop: "num" } },
                [
                  _vm.form.deleteable === false
                    ? _c("div", [_vm._v(_vm._s(_vm.form.num))])
                    : _c(
                        "el-input",
                        {
                          staticClass: "input-with-select",
                          attrs: { placeholder: "" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                slot: "prepend",
                                placeholder: "请选择",
                                disabled: _vm.form.deleteable === false,
                              },
                              slot: "prepend",
                              model: {
                                value: _vm.form.num,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "num", $$v)
                                },
                                expression: "form.num",
                              },
                            },
                            _vm._l(10, function (count) {
                              return _c("el-option", {
                                key: count,
                                attrs: { label: count, value: count },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("template", { slot: "append" }, [_vm._v("张")]),
                        ],
                        2
                      ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发放上限（总）", prop: "limitType" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            label: 0,
                            disabled: _vm.form.deleteable === false,
                          },
                          model: {
                            value: _vm.form.limitType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "limitType", $$v)
                            },
                            expression: "form.limitType",
                          },
                        },
                        [
                          _vm._v("限制\n            "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "nomargin-form-item",
                              attrs: { label: "", prop: "total" },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  staticStyle: { "margin-right": "-20px" },
                                  attrs: {
                                    disabled:
                                      ![false, undefined].includes(
                                        _vm.form.deleteable
                                      ) || _vm.form.limitType === 1,
                                    maxlength: 30,
                                    placeholder: "请输入≥1的正整数",
                                  },
                                  model: {
                                    value: _vm.form.total,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "total", $$v)
                                    },
                                    expression: "form.total",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("张"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            label: 1,
                            disabled: _vm.form.deleteable === false,
                          },
                          model: {
                            value: _vm.form.limitType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "limitType", $$v)
                            },
                            expression: "form.limitType",
                          },
                        },
                        [_vm._v("不限")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.form.id && _vm.form.limitType !== 1
                    ? _c(
                        "div",
                        {
                          staticClass: "suffix-text",
                          style: {
                            color:
                              _vm.form.ratio >= 100
                                ? "#F56C6C"
                                : _vm.form.ratio >= 90
                                ? "#f90"
                                : "",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.form.remainingCount === -1
                                  ? `发放详情：${_vm.form.issuedNum}张`
                                  : `发放详情：发放${_vm.form.ratio}%（已领：${_vm.form.issuedNum}/剩余：${_vm.form.remainingCount}）`
                              ) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "券有效期", prop: "expireType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.form.deleteable === false },
                      model: {
                        value: _vm.form.expireType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "expireType", $$v)
                        },
                        expression: "form.expireType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("固定天数"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: -1 } }, [
                        _vm._v("长期有效"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.form.expireType == 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "nomargin-form-item",
                              attrs: { label: "", prop: "expireDays" },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    maxlength: 30,
                                    placeholder: "请输入",
                                    disabled: _vm.form.deleteable === false,
                                  },
                                  model: {
                                    value: _vm.form.expireDays,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "expireDays", $$v)
                                    },
                                    expression: "form.expireDays",
                                  },
                                },
                                [
                                  _c("template", { slot: "prepend" }, [
                                    _vm._v("领取后"),
                                  ]),
                                  _vm._v(" "),
                                  _c("template", { slot: "append" }, [
                                    _vm._v("天"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form.expireType === 1
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 11 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "nomargin-form-item",
                                  attrs: { label: "", prop: "startTime" },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "开始时间",
                                      "value-format": "yyyy-MM-dd",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    model: {
                                      value: _vm.form.startTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "startTime", $$v)
                                      },
                                      expression: "form.startTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: { span: 2 },
                            },
                            [_vm._v("至")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 11 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "nomargin-form-item",
                                  attrs: { label: "", prop: "endTime" },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "结束时间",
                                      "value-format": "yyyy-MM-dd",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    model: {
                                      value: _vm.form.endTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "endTime", $$v)
                                      },
                                      expression: "form.endTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }